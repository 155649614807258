import React, { useState } from "react";

const CancellationAndRefundPolicy = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <div style={{ marginBottom: "0rem", textAlign: "left", padding: "30px" }}>
        <p style={{ marginTop: "0.5rem", fontSize: "16px" }}>
          This policy outlines the procedures for cancellations and refunds
          pertaining to events hosted on our platform:
        </p>
        <ol style={{ marginBottom: "1rem" }}>
          <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
            <span
              style={{
                fontSize: "17px",
                marginBottom: "0.5rem",
                fontWeight: "500",
              }}
            >
              Cancellation and Refund Determination:
            </span>
            <ul style={{ marginTop: "0.5rem" }}>
              <li>
                The cancellation and refund policies for events are set by the
                respective event organisers and may vary.
              </li>
              <li>
                We advise users to thoroughly review the terms & conditions as
                well as the specific cancellation and refund policy of each
                event before purchasing tickets.
              </li>
            </ul>
          </li>
          <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
            <span
              style={{
                fontSize: "17px",
                marginBottom: "0.5rem",
                fontWeight: "500",
              }}
            >
              Event Cancellation, Postponement, or Delay:
            </span>
            <ul style={{ marginTop: "0.5rem" }}>
              <li>
                In the event of cancellation, postponement, suspension, or delay
                of an event for any reason, FitFunda will not issue refunds nor
                be held liable for any losses incurred as a result.
              </li>
              <li>
                The responsibility to update registered participants and process
                refunds lies with the event organiser. Participants are advised
                to directly contact the event organiser for refund inquiries.
              </li>
            </ul>
          </li>
          <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
            <span
              style={{
                fontSize: "17px",
                marginBottom: "0.5rem",
                fontWeight: "500",
              }}
            >
              Notification of Event Changes:
            </span>
            <ul style={{ marginTop: "0.5rem" }}>
              <li>
                FitFunda will make reasonable efforts to notify ticket holders
                of event cancellations or rescheduling upon receiving relevant
                authorization from the event organiser.
              </li>
              <li>
                However, we cannot guarantee that ticket holders will be
                informed of such changes before the scheduled event date, as
                event organisers maintain control over event communication.
              </li>
            </ul>
          </li>

          {showMore && (
            <>
              <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
                <span
                  style={{
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  Ticket Availability and Payment:
                </span>
                <ul style={{ marginTop: "0.5rem" }}>
                  <li>
                    If a ticket purchase is attempted when tickets are sold out, the
                    transaction will fail, and the ticket will be automatically
                    cancelled. Any payment made for such tickets will be refunded to
                    the original mode of payment.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
                <span
                  style={{
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  Refund Processing Time:
                </span>
                <ul style={{ marginTop: "0.5rem" }}>
                  <li>
                    Refunds for cancelled tickets will be processed through our
                    payment gateway.
                  </li>
                  <li>
                    Please note that it may take 7-10 working days for the refunded
                    amount to reflect in the original mode of payment used for the
                    transaction.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
                <span
                  style={{
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  User Responsibility:
                </span>
                <ul style={{ marginTop: "0.5rem" }}>
                  <li>
                    It is the responsibility of users to ascertain whether an event
                    has been cancelled, rescheduled, or postponed, as well as to be
                    aware of any rearranged event dates and times.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
                <span
                  style={{
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  Dispute Resolution:
                </span>
                <ul style={{ marginTop: "0.5rem" }}>
                  <li>
                    In case of disputes regarding cancellations or refunds, FitFunda
                    will endeavour to facilitate communication between the event
                    organiser and the ticket holder to reach a resolution.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "1rem", fontSize: "14px" }}>
                <span
                  style={{
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  Policy Updates:
                </span>
                <ul style={{ marginTop: "0.5rem" }}>
                  <li>
                    FitFunda reserves the right to update or modify this
                    cancellation and refund policy at any time. Users will be
                    notified of any changes through our platform.
                  </li>
                </ul>
              </li>
            </>
          )}
        </ol>
        <p style={{ fontSize: "16px" }}>
          This policy aims to provide clarity and transparency regarding the
          cancellation and refund procedures for events hosted on FitFunda.
          Users are encouraged to familiarise themselves with this policy before
          purchasing tickets for any event.
        </p>
        {showMore ? (
          <a
            href="#"
            onClick={toggleShowMore}
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            View less...
          </a>
        ) : (
          <p
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "11pt",
            }}
          >
            <a href="#" onClick={toggleShowMore} style={{ fontSize: "17px" }}>
              View more...
            </a>
          </p>
        )}
      </div>
    </>
  );
};

export default CancellationAndRefundPolicy;
