import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import StateDropdown from "../../controls/dropdown/StateDropdown";
import * as Call from "../../../utils/validations";
import { SetOrganizerBasicDetails } from "../../../services/userAPI";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import FileUploadSingle from "../../controls/fileUploader/FileUploadSingle";
import * as initialState from "../../../services/initialState";
import CityByStateIdDropdown from "../../controls/dropdown/CityByStateIdDropdown";
import Spinner from "../../controls/spinner/Spinner";

// Form initial Data
let initialData = initialState.OrganizerProfile;
let mParam = initialState.OrganizerProfile.isRequired;
let ImagePath = process.env.REACT_APP_USERS;

const OrganizerProfileDetails = ({
  onStepComplete,
  organizerDetails,
  onChange,
}) => {
  const userData = useSelector((loginData) => loginData.login.login);

  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  // const [isMobileVerified, setIsMobileVerified] = useState(true);
  // const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [validationSummary, setValidationSummary] = useState("");
  const [loading, setLoading] = useState(false); //spinner

  const thisStepCompleted = (value) => {
    switch (value) {
      case 1:
      case 2:
      case 3:
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (organizerDetails) {
      const stepNumber = parseInt(
        organizerDetails?.stepCompleted.replace(/\D/g, ""),
        10
      );

      if (thisStepCompleted(stepNumber)) {
        initialData = { ...initialData, ...organizerDetails };
        initialData["IsValid"] = mParam;
        setInputValue(() => initialData);
        setStateId(organizerDetails?.stateId);
        setCityId(organizerDetails?.cityId);
      }
    }
  }, [organizerDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "businessName":
          val = !Call.IsEmpty(value);
          if (val) msg = "Invalid " + value;
          break;

        case "businessEmail":
          val = Call.IsValidEmail(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "businessMobile":
          val = Call.IsValidMobileNo(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 10 digit number ";
          }
          break;

        case "businessAddress":
          val = !Call.IsEmpty(value);
          if (val) msg = "Invalid " + value;
          break;

        case "businessCity":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Please select city ";
          break;

        case "businessState":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Please select state ";
          break;

        case "businessPincode":
          val = Call.IsValidPinCode(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 6 digit pincode ";
          }
          break;

        case "businessCountry":
          val = Call.IsValidCountry(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "businessWebsite":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Invalid " + value;
          break;

        default:
          break;
      }
    }

    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));

    return val;
  };

  const saveAndProceed = async (e) => {
    e.preventDefault();

    if (
      IsValid &&
      IsValid.businessName &&
      IsValid.businessEmail &&
      IsValid.businessMobile &&
      IsValid.businessAddress &&
      IsValid.businessPincode &&
      IsValid.businessCountry &&
      inputValue.businessState !== "" &&
      inputValue.businessCity !== "" &&
      inputValue.businessLogo !== ""
    ) {
      inputValue.userId = userData.login.user.userId;

      // send data to server
      setLoading(true);
      const result = await SetOrganizerBasicDetails(
        inputValue,
        userData.login.token
      );
      setLoading(false);

      //next step
      if (result) {
        if (result?.success) {
          onChange(result?.data);
          onStepComplete(1);
        } else {
          alert("Error:-" + result?.message);
        }
      } else {
        alert(API_RESONSE_FAILED);
      }
    } else {
      // alert("Enter required fields");
      let displaMsg = "";
      for (let key in mParam) {
        if (!inputValue?.IsValid[key]) {
          if (key === "businessName") {
            displaMsg += "<li>" + "Business Name" + "</li>";
          } else if (key === "businessEmail") {
            displaMsg += "<li>" + "Business Email" + "</li>";
          } else if (key === "businessMobile") {
            displaMsg += "<li>" + "Business Mobile" + "</li>";
          } else if (key === "businessAddress") {
            displaMsg += "<li>" + "Business Address" + "</li>";
          } else if (key === "businessPincode") {
            displaMsg += "<li>" + "Business Pin Code" + "</li>";
          } else if (key === "businessCountry") {
            displaMsg += "<li>" + "Select Country" + "</li>";
          } else if (key === "businessState") {
            displaMsg += "<li>" + "Select State" + "</li>";
          } else if (key === "businessCity") {
            displaMsg += "<li>" + "Select City" + "</li>";
          }

          //displaMsg += "<li>" + key + "</li>";
        }
      }
      //
      if (displaMsg !== "") {
        displaMsg =
          "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
        setValidationSummary(() => displaMsg);
      }
    }
  };
  const setInputState = (name, value, msg, val) => {
    //set value
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
  };

  const handleFileChange = (file) => {
    //inputValue.businessLogo = file;
    // if (file?.type === "image/*") {
    setInputState("businessLogo", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
      // }
    }
  };

  const handleStateChange = (event) => {
    let stateId = event.target.value;
    let stateName = event.target.options[event.target.selectedIndex].text;
    stateId = parseInt(stateId);

    //inputValue.businessState = stateName;
    //setInputState("businessState", stateName, "", stateId > 0 ? true : false);
    //set value
    setInputValue((prev) => ({
      ...prev,
      ["businessState"]: stateName,
      ["businessCity"]: "",
      errors: { ...errors, ["businessState"]: "", ["businessCity"]: "" },
      IsValid: {
        ...IsValid,
        ["businessState"]: stateId > 0 ? true : false,
        ["businessCity"]: false,
      },
    }));

    setStateId(stateId);
    setCityId(0);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleCityChange = (event) => {
    let cityId = event.target.value;
    let cityName = event.target.options[event.target.selectedIndex].text;
    //inputValue.businessCity = cityName;
    cityId = parseInt(cityId);
    setInputState("businessCity", cityName, "", cityId > 0 ? true : false);
    setCityId(cityId);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  /*   const verifyMobile = (event) => {
    if (IsValid.businessMobile) {
      alert("verify this");
    } else {
      alert("Please enter valid mobile number to verify.");
    }
  }; 

  const verifyEmail = (event) => {
    if (IsValid.businessEmail) {
      alert("verify this");
    } else {
      alert("Please enter valid email to verify.");
    }
  };

   const handleWebSite = (event) => {
    inputValue.businessWebsite = event.target.value;    
  }; */

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div class="card p-4 organizer-logo my-3">
            {/* <h5>Profile Details</h5> */}
            <form>
              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Name Of Business<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="businessName"
                  name="businessName"
                  type="text"
                  placeholder="Please Enter Business Name"
                  isValid={inputValue?.IsValid?.businessName}
                  errorMsg={inputValue?.errors?.businessName}
                  value={inputValue.businessName}
                  onChange={handleChange}
                  maxlength={50}
                  autofocus
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Business Email<span class="text-danger">*</span>
                </label>

                <div class="input-group">
                  <InputTextBox
                    id="businessEmail"
                    name="businessEmail"
                    type="email"
                    placeholder="Business Email"
                    isValid={inputValue?.IsValid?.businessEmail}
                    errorMsg={inputValue?.errors?.businessEmail}
                    value={inputValue.businessEmail}
                    onChange={handleChange}
                    maxlength={30}
                  />
                  {/* <label
              class="input-group-text"
              for="inputGroupFile02"
              onClick={verifyEmail}
            >
              <i class="bi bi-cloud-arrow-up mx-1 select-upload mt-1"></i>Verify
            </label> */}
                </div>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Business Contact Number<span class="text-danger">*</span>
                </label>

                <div class="input-group">
                  <InputTextBox
                    id="businessMobile"
                    name="businessMobile"
                    placeholder="Business Mobile"
                    type="text"
                    value={inputValue.businessMobile}
                    isValid={inputValue?.IsValid?.businessMobile}
                    errorMsg={inputValue?.errors?.businessMobile}
                    onChange={handleChange}
                    maxlength={10}
                    prefixText="+91"
                    className="numberWithoutArrowkey"
                  />

                  {/* <label
              class="input-group-text"
              for="inputGroupFile02"
              onClick={verifyMobile}
            >
              <i class="bi bi-cloud-arrow-up mx-1 select-upload mt-1"></i>Verify
            </label> */}
                </div>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Website Link
                </label>

                <InputTextBox
                  id="businessWebsite"
                  name="businessWebsite"
                  placeholder="Website Link"
                  type="text"
                  isValid={inputValue?.IsValid?.businessWebSite}
                  errorMsg={inputValue?.errors?.businessWebSite}
                  value={inputValue.businessWebsite}
                  onChange={handleChange}
                  maxlength={50}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  getFileDetail={handleFileChange}
                  title={"Business Logo"}
                  fileType={"image"}
                >
                  fileData
                </FileUploadSingle>

                {typeof inputValue?.businessLogo === "string" &&
                  inputValue?.businessLogo && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessLogo/" +
                          inputValue?.businessLogo
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />

                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessLogo/" +
                          inputValue?.businessLogo
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue?.businessLogo})
                      </a>
                    </div>
                  )}
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Business Address<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="businessAddress"
                  name="businessAddress"
                  placeholder="Address"
                  type="text"
                  isValid={inputValue?.IsValid?.businessAddress}
                  errorMsg={inputValue?.errors?.businessAddress}
                  value={inputValue?.businessAddress}
                  onChange={handleChange}
                  maxlength={70}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Pin Code<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="businessPincode"
                  name="businessPincode"
                  placeholder="Business Address PinCode"
                  isValid={inputValue?.IsValid?.businessPincode}
                  errorMsg={inputValue?.errors?.businessPincode}
                  value={inputValue.businessPincode}
                  onChange={handleChange}
                  maxlength={6}
                />
              </div>

              <div class="my-3 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Select County<span class="text-danger">*</span>
                </label>
                <select
                  class="form-select p-2"
                  aria-label="Default select example"
                  value={inputValue.businessCountry}
                  onChange={handleChange}
                  name="businessCountry"
                >
                  <option selected="">Select County</option>
                  <option value="India">India</option>
                </select>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Select State<span class="text-danger">*</span>
                </label>
                <StateDropdown
                  name="state"
                  stateId={stateId}
                  onStateChange={handleStateChange}
                  value={inputValue?.stateId}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Select City<span class="text-danger">*</span>
                </label>
                <CityByStateIdDropdown
                  name="city"
                  stateId={stateId}
                  cityId={cityId}
                  onCityChange={handleCityChange}
                  value={inputValue?.cityId}
                />
              </div>
              <div class="mb-3 text-center">
                <Button onClick={saveAndProceed}>Save & Proceed</Button>
              </div>
              {validationSummary !== "" && (
                <div
                  class="alert alert-danger"
                  role="alert"
                  dangerouslySetInnerHTML={{ __html: validationSummary }}
                ></div>
              )}
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default OrganizerProfileDetails;
