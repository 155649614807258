import * as React from "react";
import { Link } from "react-router-dom";

function FitFundaInfo(props) {
  return (
    <>
      <div class="row text-start mx-2 my-5">
        <div class="col-12 col-md-6">
          {/* <h1 class="fw-600">Logo</h1>
          <p class="footer-info">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since
          </p> */}
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>About</b>
          </h6>
          <p class="footer-text mp-2">
            <Link to={"/about-us"}>About Us</Link>
          </p>
          <p class="footer-text mp-2">
            <Link to={"/sitemap"}>Sitemap</Link>
          </p>

          <p class="footer-text mp-2">
            <Link to={"https://www.vrattanta.com/blog/"}>Blog</Link>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>Learn More</b>
          </h6>

          <p class="footer-text mp-2">
            <Link to={"/navigate-fitfunda"}>Navigate Fit-Funda</Link>
          </p>
          <p class="footer-text mp-2">
            <Link to={"/pricing"}>Pricing</Link>
          </p>         
          <p class="footer-text mp-2">
            <Link to={"/policies"}>Policies</Link>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>Contact Us</b>
          </h6>
          <p class="footer-text mp-2">
            Vrattanta Endurance & Sports Viman Nagar, Pune
          </p>
          <p class="footer-text mp-2">Email: fitfundasupport@vrattanta.com</p>
          <p class="footer-text mp-2">Phone: +91-9529478968</p>
        </div>
      </div>
    </>
  );
}

export default FitFundaInfo;
