import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  addToCartEventRequest,
  fetchCartEventsRequest,
} from "../../redux/actions/events";
import { FOLDER_EVENT_PHOTO } from "../../utils/Constants";
import * as comFun from "../../utils/commonFun";
import * as constants from "../../utils/Constants";
import { GetPaymentBreakUpOfTicket } from "../../services/paymentAPI";
import InputTextbox from "../controls/input/InputTextBox";
import Button from "../controls/buttons/Button";
import * as Call from "../../utils/validations";
import Modal from "../controls/popup/Modal";
import InputTextBox from "../controls/input/InputTextBox";
import Icon from "../controls/icons/Icon";
import Spinner from "../controls/spinner/Spinner";

//
let ImagePath = process.env.REACT_APP_EVENTS;
const initialData = {
  couponCode: "",
};

//main function
const CheckOutSummary = ({
  summaryData,
  cartEvents,
  addSummary,
  fetchSummary,
  eventDetailsData,
  paymentBreakup,
  cameFrom,
  isWidget,
  widgetPath,
}) => {
  //declare start
  const [summary, setsummary] = useState(summaryData);
  const [ticketBreakUpResponse, setTicketBreakUpResponse] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  const navigate = useNavigate();
  let { eventId } = useParams();
  const { eventName } = useParams();
  //let tempSummary = "";
  // console.log(summary);
  const [loading, setLoading] = useState(false); // loading spinner
  //contact OrganizerPopup
  const [showModal, setShowModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [loadingBreakup, setLoadingBreakup] = useState(false); // Breakup loading

  //declare end

  //function/events/methods
  const handleClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSendEmail = () => {
    const mailtoLink = `mailto:${
      eventDetailsData.supportEmail
    }}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody
    )}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    setsummary(() => summaryData);
  }, [summaryData]);

  const cartEvents1 = useSelector(
    (state) => state.events.cartEvents.cartEvents
  );

  if (eventDetailsData) {
    eventId = eventDetailsData?.eventId;
  }

  useEffect(() => {
    let cleanUp = true;
    if (cleanUp)
      if (summary?.length === 0 && cartEvents1 && cartEvents1 !== null) {
        let setData = true;
        try {
          for (const key in cartEvents) {
            if (cartEvents[key].eventId === eventId) {
              document.getElementById(key).value = parseInt(
                cartEvents[key].orderQuantity
              );
            } else {
              setData = false;
              //clear store
              comFun.removeItem(constants.LOCAL_CART_EVENT);
            }
          }
        } catch {}
        if (setData) setsummary(() => cartEvents);
      }

    return () => (cleanUp = false);
  });

  //
  let total = 0;
  let totalOrderQuantity = 0;
  //eventId = eventDetailsData.eventId;
  let ImageFullPath =
    ImagePath +
    eventId +
    FOLDER_EVENT_PHOTO +
    eventDetailsData?.eventPhotos[0]?.docName;

  //

  useEffect(() => {
    GetSelectedTicketBreakUp();
  }, [summary]);

  const GetSelectedTicketBreakUp = async () => {
    if (window.location.pathname.includes("/event-checkout/")) {
      let postValues = {
        eventId: "",
        ticketData: {},
        discountCode: "",
      };

      for (const item in summary) {
        const ticketId = summary[item].ticketId;
        const orderQuantity = parseInt(summary[item].orderQuantity);

        postValues.ticketData[ticketId.toString()] = orderQuantity;
      }

      postValues.eventId = eventId;
      postValues.discountCode = inputValue.couponCode;

      if (Object.keys(postValues.ticketData).length > 0) {
        setLoadingBreakup(true);
        setLoading(true);
        const getSelectedTicketBreakUp = await GetPaymentBreakUpOfTicket(
          postValues
        );
        if (getSelectedTicketBreakUp?.success) {
          setLoadingBreakup(false);
          setLoading(false);
          setTicketBreakUpResponse(() => getSelectedTicketBreakUp.data);
          paymentBreakup(getSelectedTicketBreakUp.data.detailedPaymentBreakup);
        } else {
          setLoadingBreakup(false);
          setLoading(false);
          alert(getSelectedTicketBreakUp?.error);
        }
      }
    }
  };
  const [showBreakupUI, setShowBreakup] = useState("hide");
  const showBreakup = () => {
    if (showBreakupUI == "hide") 
    {setShowBreakup(() => "show");
  // setLoading(true);
} else {
    setShowBreakup(() => "hide");
    // setLoading(false);
  }
    //alert("ok");
  };
  //
  const loadSummary = () => {
    let tempSummary = [];

    for (const key in summary) {
      if (summary[key].orderQuantity > 0) {
        tempSummary.push(
          <li class="list-group-item">
            <div class="row">
              <div class="col text-start">
                <span>
                  {/* ₹ */}
                  <Icon iconName="rupee" width="15px" height="30px" />{" "}
                  {Number(summary[key].ticketPrice).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}                  
                </span>
                <span> ({summary[key].orderQuantity} item)</span>
              </div>
              <div class="col text-end">
                {/* ₹ */}
                <Icon iconName="rupee" width="15px" height="30px" />{" "}
                {Number(summary[key].orderQuantity * summary[key].ticketPrice).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}                
              </div>
            </div>
          </li>
        );
        total += summary[key].orderQuantity * summary[key].ticketPrice;
        totalOrderQuantity += summary[key].orderQuantity;
      }
    }

    if (
      total > 0 &&
      window.location.pathname.includes("/event-checkout/") &&
      ticketBreakUpResponse !== ""
    ) {
      const numberSeperator = (value) => {
        return Number(value).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      };
      
     
      let config = [
        { title: "Apply Coupon", value: 0, msg: "", class: "" },
        {
          title: "<b>Total</b>",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.totalPayableByBuyer),
          msg:
            ticketBreakUpResponse?.uiPaymentBreakup?.note !== ""
              ? "*" + ticketBreakUpResponse.uiPaymentBreakup.note
              : "",
          class: "",
        },
        {
          title: "Discount",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.discount),
          msg:  ticketBreakUpResponse?.uiPaymentBreakup.discountName !== "" && ticketBreakUpResponse?.uiPaymentBreakup.discountName !== null ? 
          "("+ticketBreakUpResponse?.uiPaymentBreakup.discountName+")" : "",
          class: "hide",
        },       
        {
          title: "Net Ticket Price",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.ticketPrice),
          msg: "",
          class: "hide",
        },
        {
          title: "GST",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.gst),
          msg: "",
          class: "hide",
        },
        {
          title:
            ticketBreakUpResponse?.uiPaymentBreakup?.note === ""
              ? "PG Fee"
              : "PG Fee (*)",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.paymentGatewayFee),
          msg: "",
          class: "hide",
        },
        {
          title:
            ticketBreakUpResponse?.uiPaymentBreakup?.note === ""
              ? "FitFunda Fee "
              : "FitFunda Fee (*)",
          value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.fitfundaFee),
          msg: "",
          class: "hide",
        },
      ];

      if (
        parseFloat(ticketBreakUpResponse?.uiPaymentBreakup?.platformUsageFee) >
        0
      ) {
        const configPlatformFee = [
          {
            title: "Platform Fee",
            value: numberSeperator(ticketBreakUpResponse?.uiPaymentBreakup.platformUsageFee),
            msg: "",
            class: "hide",
          },
        ];

        config = [...config, ...configPlatformFee];
      }

      for (let key in config) {
        tempSummary.push(
          <li
            className={`list-group-item ${
              showBreakupUI === config[key]["class"] ? "d-none" : ""
            }`}
          >
            <div class="row">
              <div
                class="col text-start"
                dangerouslySetInnerHTML={{
                  __html: config[key]["title"],
                }}
              ></div>
              <div class="col text-end">
                {config[key]["title"] === "Apply Coupon" ? (
                  <>
                    <div className="row">
                      <InputTextbox
                        id="couponCode"
                        name="couponCode"
                        placeholder="Code"
                        isValid={inputValue?.IsValid?.couponCode}
                        //errorMsg={inputValue?.errors?.couponCode}
                        value={inputValue.couponCode}
                        onChange={handleChange}
                      />
                      <br />
                      {loadingBreakup ? (
                  <span
                    className="loaderBreakup"
                    style={{marginLeft: "110px"}}
                  ></span>
                ) : (
                      <Button
                        primary
                        className="m-1"
                        onClick={GetSelectedTicketBreakUp}
                      >
                        Apply
                      </Button>
                      )}
                    </div>
                    
                  </>
                ) : (
                  <>
                    ₹ <b>{config[key]["value"]}</b>
                    <br />
                    {config[key]["title"] == "<b>Total</b>" && (
                      <a
                        style={{ color: "pointer" }}
                        onClick={() => showBreakup()}
                        href="#"
                      >
                        View Breakup
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
            <p>
              {config[key]["title"] === "Apply Coupon"
                ? inputValue?.errors?.couponCode
                : config[key]["msg"]}
            </p>
          </li>
        );      
      }
    } else {
      tempSummary.push(
        <li class="list-group-item">
          <div class="row">
            <div class="col text-start">
              <b>Total</b>
            </div>
            <div class="col text-end">
              <b>
                {/*  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="13px"
                  height="13px"
                  viewBox="0 0 512.000000 753.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,753.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M231 7161 c-122 -186 -221 -340 -221 -343 0 -3 273 -8 608 -11 595 -6 726 -12 947 -43 361 -50 637 -165 831 -347 36 -34 85 -86 109 -117 42 -54 145 -227 145 -242 0 -4 -496 -9 -1102 -10 l-1102 -3 -222 -340 -222 -340 1370 -3 1371 -2 -6 -93 c-29 -427 -318 -819 -758 -1026 -214 -100 -453 -162 -738 -191 -57 -5 -305 -10 -552 -10 l-449 0 2 -336 3 -336 1340 -1683 1340 -1684 618 -1 619 0 -39 48 c-21 27 -649 808 -1395 1738 -747 929 -1359 1694 -1360 1699 -2 6 17 11 42 13 103 6 323 35 440 59 783 157 1414 590 1714 1173 98 191 170 407 192 578 l7 52 437 0 437 0 222 340 c121 186 221 342 221 345 0 2 -302 6 -672 7 l-671 3 -18 94 c-41 213 -131 417 -260 587 -27 36 -49 67 -49 69 0 3 278 6 617 7 l616 3 134 205 c73 113 174 267 223 343 l89 137 -2318 0 -2319 0 -221 -339z" />
                  </g>
                </svg>{" "} */}
                <Icon iconName="rupee" width="15px" height="30px" />
                {Number(total).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </b>
            </div>
          </div>
          <p>Prices are excluding Booking Fees</p>
        </li>
      );
    }

    return tempSummary;
  };

  //loadSummary();
  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        // case "couponCode":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please enter coupon code";
        //   } else {
        //     val = Call.IsValidCouponCode(value);
        //     if (!val) msg = "Invalid " + value;
        //   }
        //   break;

        case "couponCode":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter coupon code.";
          break;

        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const handleCheckoutSummary = () => {
    console.log("from CheckOutSummary send summary to redux store");
    console.log(summary);
    /*   cartEvents = {
      events: {
        cartEvents: summary,
      },
    }; */
    cartEvents = summary;
    addSummary(cartEvents);
    if (isWidget && Boolean(isWidget) === true) {
      navigate(widgetPath + "/event-checkout/" + eventId + "/" + eventName);
    } else navigate("/event-checkout/" + eventId + "/" + eventName);
  };

  /*  useEffect(() => {
    cartEvents = fetchSummary();
    console.log("get summary from redux store");
    console.log(cartEvents);
    //if (!summary && cartEvents) summary = cartEvents;
    summary = cartEvents;
  }, [fetchSummary]);
 */

  //main return
  return (
    <>
      <div class="row">
        <div class="col-12 mb-4">
          {summary ? (
            <>
              <div class="card">
                <div class="card-header text-start">
                  <b>Summary</b>
                </div>

                <ul
                  class="list-group list-group-flush text-start event-price-summary"
                  /* dangerouslySetInnerHTML={{ __html: tempSummary }} */
                >
                  {loadSummary()}
                </ul>
              </div>
              <div class="detail-eventinfo-card ">
                {totalOrderQuantity == 0 ? (
                  <div class="card">
                    <div class="card-body detail-eventinfo-card">
                      <a
                        href="#"
                        class="btn btn-dark w-100 book-btn my-2 disabled"
                      >
                        Proceed
                      </a>
                    </div>
                  </div>
                ) : (
                  <>
                    {cameFrom !== "EventCheckOut" && (
                      <button
                        className="btn btn-dark w-100 book-btn mt-3"
                        onClick={handleCheckoutSummary}
                      >
                        Proceed
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div class="card">
              <div class="card-body detail-eventinfo-card">
                <a href="#" class="btn btn-dark w-100 book-btn my-2 disabled">
                  Proceed
                </a>
              </div>
            </div>
          )}
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body detail-eventinfo-card">
              <div class="d-flex flex-row align-items-start">
                <div class="text-start">
                  <img
                    class="mx-20img-fluid img-thumbnail mx-20"
                    src={ImageFullPath}
                    alt="New Event"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </div>
                <div class="ms-1 text-start ps-1">
                  <h1>Have a question?</h1>
                  <p>Send your queries to the event organizer</p>
                </div>
              </div>
              <a
                href="#"
                class="btn btn-outline-dark w-100 book-btn mt-2"
                onClick={handleClick}
              >
                Contact Organizer
              </a>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          title="Contact Organizer"
          onClose={handleCloseModal}
          cancleText="Cancel"
          onSuccess={handleSendEmail}
          successText="Send Email"
        >
          <div className="modal-body manage-category-modal">
            <form action="" method="post">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Support Email Id
                </label>
                <div class="" href="#">
                  <InputTextBox
                    id="email"
                    name="email"
                    placeholder="Email Id"
                    value={eventDetailsData.supportEmail}
                    disabled
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Support Mobile No.
                </label>
                <div class="" href="#">
                  <InputTextBox
                    id="supportMobile"
                    name="supportMobile"
                    placeholder="Mobile"
                    type="number"
                    value={eventDetailsData.supportMobile}
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {loading && <Spinner/>}
    </>
  );
};

const mapStateToProps = (state) => ({
  cartEvents: state.events.cartEvents.cartEvents,
  /* ? state.events.cartEvents.cartEvents[0]
    : state.events.cartEvents.cartEvents, */
});
const mapDispatchToProps = (dispatch) => ({
  addSummary: (cartEvents) => dispatch(addToCartEventRequest(cartEvents)),
  fetchSummary: () => dispatch(fetchCartEventsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutSummary);
//export default connect(null, mapDispatchToProps)(CheckOutSummary);
//export default CheckOutSummary;
