import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { NO_IMAGE_PATH, FOLDER_EVENT_PHOTO } from "../../utils/Constants";
import Icon from "../controls/icons/Icon";
import Accordion from "../controls/accordion/Accordion";
import "./EventCardLarge.css";
import Modal from "../controls/popup/Modal";
import InputTextBox from "../controls/input/InputTextBox";
import ShowGoogleMap from "../controls/googleMap/ShowGoogleMap";
import {
  convertDateTimeToISTForUI,
  convertDateToIST,
} from "../../utils/DateTimeUtility";
import { GetOrganizerLogo } from "../../services/eventAPI";

//
let ImagePath = process.env.REACT_APP_EVENTS;
let eventId;

//main function
const EventCardTypeLarge = ({ eventDetails, hideRightPanel, cameFrom }) => {
  //declare start
  eventId = useParams();
  const { eventName } = useParams();

  if (eventDetails) eventId = eventDetails.eventId;
  //const {eventId,eventName} =useParams();
  const [logo, setLogo] = useState(""); //for logo
  //for handleEmail
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  //declare end

  useEffect(() => {
    const GetLogo = async () => {
      try {
        if (eventDetails?.userId) {
          const response = await GetOrganizerLogo(eventDetails?.userId);
          if (response.success) {
            const logoPath =
              process.env.REACT_APP_USERS +
              eventDetails?.userId +
              "/businessLogo/" +
              response.data;
            setLogo(logoPath);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetLogo();
  }, [eventDetails]);

  //functions/events/methods
  function getFaqData(faqs) {
    let faqList = "<table class='table table-bordered table-striped'><tbody>";
    faqList += faqs.map((item, index) => {
      return (
        "<tr><td><b>Question</b> :- " +
        item.question +
        "</td></tr><tr><td>Answer :- " +
        item.answer +
        "</td></tr>"
      );
    });
    faqList += "</tbody></table>";
    return faqList;
  }

  function getAccordionData(event) {
    try {
      let eventTab = [
        {
          tabNo: 1,
          header: "About Event",
          iconName: "aboutevent",
          text: event.eventDescription,
        },
        {
          tabNo: 2,
          header: "Venue",
          iconName: "Venue",
          content: (
            <>
              <table class="table table-bordered1 mdnEDTable">
                <tbody>
                  <tr>
                    <td>
                      <span>Address</span>
                    </td>
                    <td>{event.address}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>Area</span>
                    </td>
                    <td>{event.area}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>City</span>
                    </td>
                    <td>{event.cityName}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>State</span>
                    </td>
                    <td>{event.stateName}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>Pin code</span>
                    </td>
                    <td>{event.pincode}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ),
        },
        {
          tabNo: 3,
          header: "Terms and Condition",
          iconName: "tnc",
          text: event.terms,
        },
        {
          tabNo: 4,
          header: "FAQ",
          iconName: "faq",
          text: getFaqData(eventDetails.faqs),
        },
      ];

      return eventTab;
    } catch (error) {}
  }

  const location = useLocation();
  const isEventDetailsPage = location.pathname.includes("/eventdetails/");

  const handleSendEmail = () => {
    const mailtoLink = `mailto:${
      eventDetails.supportEmail
    }?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      {eventDetails && (
        <>
          <div class="row">
            <div class="col-sm-12 mt-3 col-md-8 col-xl-9 cePreview">
              <EventImageGallary images={eventDetails.eventPhotos} />
              <EventDetails event={eventDetails} cameFrom={cameFrom} />
              <Accordion items={getAccordionData(eventDetails)} />

              <div class="my-5">
                <label for="exampleInputEmail1" class="form-label">
                  Map
                </label>
                <ShowGoogleMap
                  searchInput={{
                    address: eventDetails?.address,
                    area: eventDetails?.area,
                    city: eventDetails?.cityName,
                    state: eventDetails?.stateName,
                  }}
                  cameFrom="EventCardTypeLarge"
                />
              </div>
              {/* Contact Organizer Section */}
              {/* <div className="my-5">
                <h3>Contact Organizer</h3>
                <table className="table table-bordered org-table text-start">
                  <thead>
                    <tr>
                      <th>Organizer Logo</th>
                      <th>Mobile</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>
                        {logo ? (
                          <img
                            src={logo}
                            alt="Organizer Logo"
                            style={{ maxWidth: "70px", maxHeight: "100px" }}
                            className="centered-image"
                          />
                        ) : (
                          "No Logo Available"
                        )}
                      </td>
                      <td>{eventDetails.supportMobile}</td>
                      <td>{eventDetails.supportEmail}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              {isEventDetailsPage && (
                <>
                  <h3
                    style={{
                      fontSize: "16px",
                      textAlign: "left",
                      marginLeft: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Contact Organizer
                  </h3>
                  <div className="d-flex flex-row align-items-start mt-4">
                    <div className="text-start">
                      <img
                        className="mx-20 img-fluid img-thumbnail mx-20"
                        src={logo}
                        alt="Organizer Logo"
                        style={{ maxWidth: "75px", maxHeight: "75px" }}
                      />
                    </div>
                    <div className="ms-1 text-start ps-3">
                      <h5>Have a question?</h5>
                      <p>Send your queries to the event organizer.</p>
                    </div>
                  </div>
                  <div class="my-3 running-details d-flex flex-wrap align-items-start mobile-view">
                    <div class="d-flex p-1 align-items-start">
                      <Icon iconName="phone" />
                      <span class="ms-2 ">
                        +91-&nbsp;
                        {eventDetails.supportMobile}
                      </span>
                    </div>
                    <div
                      class="d-flex p-1 text-start email-left"
                      onClick={handleSendEmail}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon iconName="emailsmall" />
                      <span class="ms-2 txtBlue">
                        {eventDetails.supportEmail}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            {hideRightPanel == true ? (
              <></>
            ) : (
              <div class="col-sm-3 mt-3 d-sm-none d-md-block col-md-4 col-xl-3">
                <EventRightPanel event={eventDetails} cameFrom={cameFrom} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const EventImageGallary = ({ images, prize }) => {
  let imagesPath =
    images && images[0]
      ? ImagePath + eventId + FOLDER_EVENT_PHOTO + images[0].docName
      : NO_IMAGE_PATH;
  const [selectedImg, setSelectedImg] = useState(imagesPath);
  useEffect(() => {
    setSelectedImg(() => imagesPath);
  }, [images]);
  const handleOnError = () => {
    setSelectedImg(NO_IMAGE_PATH);
  };
  const handleOnError1 = (event) => {
    event.target.src = NO_IMAGE_PATH;
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <img
            src={selectedImg}
            className="img-fluid"
            onError={handleOnError}
            alt="Event-Photo"
          />
        </div>
      </div>
      <div className="d-flex flex-row ">
        {/* row my-3 */}
        {images &&
          images.map((item, index) => {
            let ImageFullPath =
              ImagePath + eventId + "/eventPhoto/" + item.docName;
            return (
              <>
                <div className="p-2 ">
                  {/*  col d-flex */}
                  <img
                    src={ImageFullPath}
                    onClick={() => setSelectedImg(ImageFullPath)}
                    onMouseEnter={() => setSelectedImg(ImageFullPath)}
                    className="img-fluid-thumnail"
                    onError={handleOnError}
                  />
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

const EventDetails = ({ event, cameFrom }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const handleShareClick = () => {
    if (navigator.share) {
      // If Web Share API is supported
      // const firstImageUrl =
      //   event.eventPhotos && event.eventPhotos.length > 0
      //     ? ImagePath + event.eventId + FOLDER_EVENT_PHOTO + event.eventPhotos[0].docName
      //     : NO_IMAGE_PATH;

      navigator
        .share({
          title: `${event.eventName}`,
          // text: `${event.eventDescription}`, The description is commented out because it appears in HTML format when shared. This issue has also been raised in TP-110.
          // text: `Check out this image: ${firstImageUrl}`,
          url: `${window.location.href}`,
        })
        .then(() => {
          console.log("Shared successfully");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      // Fallback for browsers that do not support Web Share API
      setShowShareOptions(!showShareOptions);
    }
  };
  const location = useLocation();
  const isEventDetailsPage = location.pathname.includes("/eventdetails/");

  return (
    <>
      {event && (
        <>
          <div class="d-flex">
            <div class="p-2 flex-grow-1 text-start game-name" id="eventto">
              {event.eventName}
            </div>
            <div class="p-2" onClick={handleShareClick}>
              {/* <a href=""> */}
              <Icon iconName="share" />
              {/* </a> */}
              {showShareOptions && (
                <div>
                  {/* Render your custom share options */}
                  {/* For example, social media icons or a modal */}
                  <p>Facebook Share</p>
                  <p>Twitter Share</p>
                  <p>Email Share</p>
                </div>
              )}
            </div>
          </div>
          {/* <div class="d-flex flex-row p-2 gap-2">
            {event.ticketDetails &&
              event.ticketDetails.map((item, index) => {
                return (
                  <>
                    {item?.ticketStatus === "act" ? (
                      <>
                        <div
                          class="event-cat tooltip1"
                          aria-placeholder={item.ticketPrice}
                        >
                          {item.ticketName <= 8
                            ? item.ticketName
                            : item.ticketName.substring(0, 8)}
                          <span class="tooltiptext">
                            {item.ticketName}
                            <br />
                            Price {item.ticketPrice}{" "}
                            <p>
                              Start :-
                              {convertDateToIST(item?.ticketSaleStartDate)}
                              <br />
                              End :-
                              {convertDateToIST(item?.ticketSaleEndDate)}
                            </p>
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
          </div> */}

          <div className="event-details-container-custom">
            <div className="event-details-left-custom">
              <div className="tags-container-custom">
                {event.ticketDetails &&
                  event.ticketDetails.map((item, index) => (
                    <>
                      {item?.ticketStatus === "act" ? (
                        <div
                          key={index}
                          className="event-cat tooltip1 tag-item-custom"
                          aria-placeholder={item?.ticketPrice}
                        >
                          {item.ticketName.length <= 8
                            ? item.ticketName
                            : item.ticketName.substring(0, 8)}
                          <span className="tooltiptext">
                            {item.ticketName}
                            <br />
                            Price {item.ticketPrice}
                            <p>
                              Start :-{" "}
                              {convertDateToIST(item?.ticketSaleStartDate)}
                              <br />
                              End :- {convertDateToIST(item?.ticketSaleEndDate)}
                            </p>
                          </span>
                        </div>
                      ) : null}
                    </>
                  ))}
              </div>
            </div>
            {/* New Design */}
            {isEventDetailsPage && (
              <div className="event-details-right-custom">
                <div className="price-container-custom">
                  <div className="price-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="20px"
                      fill="white"
                      className="bi bi-currency-rupee"
                      viewBox="0 0 16 16"
                      aria-label="Currency Rupee"
                    >
                      <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"></path>
                    </svg>
                    {Number(event?.defaultPrice).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                    <span>&nbsp; Onwards</span>
                  </div>
                  {cameFrom === "PublishPage" ? (
                    <button className="btn btn-dark book-btn-custom">
                      Book Now
                    </button>
                  ) : (
                    <Link
                      className="btn btn-dark book-btn-custom"
                      to={`/event-ticket-options/${event.eventId}/${event.eventName}#eventto`}
                    >
                      Book Now
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
          <div class="my-4 running-details d-flex flex-wrap align-items-center">
            <div class="d-flex p-1 align-items-center">
              <Icon iconName="arrow" />
              <span class="ms-2 txtBlue">
                <Link
                  to={
                    "/category/" +
                    event.categoryName +
                    "/" +
                    event.categoryId +
                    "#eventto"
                  }
                >
                  # {event.categoryName}
                </Link>
              </span>
            </div>
            <div class="d-flex p-1 align-items-center">
              <Icon iconName="calender" />

              <span class="ms-2">
                {convertDateTimeToISTForUI(event?.eventStartDate)} to{" "}
                {convertDateTimeToISTForUI(event?.eventEndDate)}
              </span>
            </div>
            <div class="d-flex p-1 text-start ">
              <Icon iconName="venue" />
              <span class="ms-2 txtBlue">
                {event.address && (
                  <>
                    {event.address} |{" "}
                    <Link
                      to={
                        "/event/" +
                        event.cityName +
                        "/" +
                        event.cityId +
                        "#eventto"
                      }
                    >
                      {event.cityName}
                    </Link>{" "}
                    | {event.stateName}
                  </>
                )}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const EventRightPanel = ({ event, cameFrom }) => {
  let ImageFullPath =
    ImagePath + eventId + FOLDER_EVENT_PHOTO + event?.eventPhotos[0]?.docName;

  //contact OrganizerPopup
  const [showModal, setShowModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [logo, setLogo] = useState("");

  // End of declaration

  useEffect(() => {
    const GetLogo = async () => {
      try {
        if (event?.userId) {
          const response = await GetOrganizerLogo(event?.userId);

          if (response.success) {
            const logoPath =
              process.env.REACT_APP_USERS +
              event?.userId +
              "/businessLogo/" +
              response.data;
            setLogo(logoPath);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetLogo();
  }, [event]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSendEmail = () => {
    const mailtoLink = `mailto:${
      event.supportEmail
    }}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      {/* <div className="event-right-panel">
        <div className="content">
          {/* Add other sections of the panel here 
        </div>
        {/* <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body detail-price-card">
              <div className="btn-light event-buy-price my-2 price">
                {/* ₹ 
                <Icon iconName="rupee" width="20px" height="40px" />
                {Number(event.ticketDetails[0].ticketPrice).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
                
                <span> Onwards</span>
              </div>
              {cameFrom === "PublishPage" ? (
                <a class="btn btn-dark w-100 book-btn mt-2">Book Now</a>
              ) : (
                <Link
                  className="btn btn-dark w-100 book-btn mt-2"
                  to={
                    "/event-ticket-options/" +
                    event.eventId +
                    "/" +
                    event.eventName +
                    "#eventto"
                  }
                >
                  Book Now
                </Link>
              )}
            </div>
          </div>
        </div>  

        <div className="contact-organizer-section">
          <div className="card">
            <div className="card-body detail-eventinfo-card">
              <div className="d-flex flex-row align-items-start">
                <div className="text-start">
                  <img
                    className="mx-20 img-fluid img-thumbnail mx-20"
                    src={logo}
                    alt="New Event"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </div>
                <div className="ms-1 text-start ps-1">
                  <h1>Have a question?</h1>
                  <p>Send your queries to the event organizer</p>
                </div>
              </div>
              <a
                href="#"
                className="btn btn-outline-dark w-100 book-btn mt-2"
                onClick={handleClick}
              >
                Contact Organizer
              </a>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          title="Contact Organizer"
          onClose={handleCloseModal}
          cancleText="Cancel"
          onSuccess={handleSendEmail}
          successText="Send Email"
        >
          <div className="modal-body manage-category-modal">
            <form action="" method="post">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Support Email Id
                </label>
                <div className="input-group" href="#">
                  <InputTextBox
                    id="email"
                    name="email"
                    placeholder="Email Id"
                    value={event.supportEmail}
                    disabled
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Support Mobile No.
                </label>
                <div className="input-group" href="#">
                  <InputTextBox
                    id="supportMobile"
                    name="supportMobile"
                    placeholder="Mobile"
                    type="number"
                    value={event.supportMobile}
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )} */}
    </>
  );
};
export default EventCardTypeLarge;
