import { React, useEffect } from "react";
import SubscribeNewsLetter from "../../components/subscribe/Subscribe";
import ImageSlider from "../../components/slider/ImageSlider";
import SliderCategoryEvent from "../../components/slider/SliderEventCategory";
import EventsInCity from "../../components/event/EventsInCity";
import FitFundaInfo from "../footer/FitFundaInfo";

const Home = ({ user }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div class="container text-center">
        <ImageSlider />
        <SliderCategoryEvent />
        <EventsInCity cities={"All"} />
        <SubscribeNewsLetter />
        <FitFundaInfo />
      </div>
    </>
  );
};

export default Home;
