import React, { useState } from "react";

//main function
const PrivacyPolicy = () => {
  //declare start
  const [showMore, setShowMore] = useState(false);

  //declare end

  //functions/events/methods
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //main retrun
  return (
    <div style={{ margin: "30px" }}>
      <div>
        

        <p
          className="s2"
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "16pt",
          }}
        >
          Introduction
        </p>
        <p
          className="s4"
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "11pt",
          }}
        >
          Vrattanta Endurance and Sports, is the holding company of
          FitFunda.co.in and FitFunda.in, and its affiliates operating in the
          event management and various sports technology domain (henceforth
          referred to as 'Vrattanta', 'we', 'us', or 'our') is a corporation
          incorporated and registered in the state of Maharashtra, India, with
          its registered address at : D2-502, Lunkad Goldcoast, Viman nagar,
          Pune, Maharashtra 411014. This privacy policy ("Policy") pertains to
          all individuals engaged in various interactions with us within the
          scope of our business activities. Such interactions may arise when you
          visit our website (
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a href="http://www.fitfunda.co/" className="s3" target="_blank">
              www.fitfunda.co{" "}
            </a>
          </span>
          and
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a
              href="http://www.fitfunda.co.in/"
              className="s3 ml-1"
              target="_blank"
            >
              www.fitfunda.co.in
            </a>
          </span>
          ,) and the
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a
              href="http://www.vrattanta.com/"
              className="s3 ml-1 mr-1"
              target="_blank"
            >
              www.Vrattanta.com
            </a>
          </span>
          <span style={{ color: "#000", fontSize: "11pt" }}>
            website, whether you are a visitor, end user, customer, prospective
            customer, observer of our operations, a potential or existing
            business partner, an employee, a job applicant, an investor, or a
            supplier. By voluntarily using our Platform or creating an account
            to access it, you assume the role of an authorised user ("User,"
            "You," or "Your") and are expected to use our Platform in accordance
            with our Terms of Use.
          </span>
        </p>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "11pt",
          }}
        >
          This Policy is designed to provide You with information regarding the
          collection, processing, storage, sharing, organisation, utilisation,
          retention, and deletion of information ("Personal Data" or "Personal
          Information"), whether obtained directly or indirectly from You.
          Additionally, it outlines the protective measures we employ to
          safeguard your data and explains how you can exercise your associated
          rights. If you do not agree with the terms outlined in this policy, we
          kindly request that you refrain from accessing or using our Services
          or engaging with any other aspect of our business operations.
        </p>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "11pt",
          }}
        >
          Please note that this Policy does not encompass information collected
          by third parties, including data collected through third-party
          applications or content (such as advertising) that may be linked to or
          accessible from our applications or websites.
        </p>

        {showMore && (
          <>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Our Commitment
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              “Vrattanta Endurance and Sports” and its corporate affiliates
              ("FitFunda", “Vrattanta” or "We," "Us," "Our"), are dedicated to
              safeguarding your privacy and protecting Your Personal Data in
              accordance with this Policy. We adhere to a range of data
              protection regulations and industry best practices to ensure the
              security of Your information and to provide You with continuous
              access and control over it. This Policy furnishes comprehensive
              details on how FitFunda gathers information, in addition to
              outlining the privacy and security protocols implemented on the
              Platform.
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              In the event of any changes in how Your information is utilised,
              we will supply You with further information during our
              communications with You through various channels, such as email.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              General
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Fitfunda has developed the most comprehensive and user-friendly
              platform for hosting virtual, In-person and hybrid events. Through
              our application ("Services"), Customers (as defined below) can
              licence FitFunda’s application to connect with Attendees (as
              defined below).
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              {/* <a
            href="mailto:privacy@vrattanta.com"
            className="s4"
            target="_blank"
          ></a> */}
              When FitFunda directly collects information from you, FitFunda
              operates as the Data Controller (as defined below). However, if
              FitFunda receives information from its Customer, FitFunda serves
              as a Data Processor (as defined below) and processes data in
              accordance with the instructions provided by the Data Controller.
              If you have any inquiries concerning the handling of Your Personal
              Data by FitFunda or wish to exercise Your privacy rights, please
              contact us at{" "}
              <a href="mailto:privacy@vrattanta.com" target="_blank">
                privacy@vrattanta.com
              </a>
            </p>
            <p
              className="s2"
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Definitions
            </p>
            <ul id="l1">
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Account" refers to the self-service account created by the
                  Customer on the FitFunda Platform for utilising the Services.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Data Subject" or "Data Owners" refers to a natural person
                  whose Personal Data is processed by a Data Controller or Data
                  Processor.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Customer Data" encompasses all data submitted, displayed,
                  and/or uploaded by the Attendee,Sponsors , Booth
                  Representatives, Authorised Administrator(s), and/or the
                  Customer during registration, access, event hosting, and/or
                  other usage of the Platform.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Customer," "Data Controllers," refers to the Organizer who
                  has a service agreement with FitFunda to access the FitFunda
                  Platform for hosting in-person , virtual events and hybrid
                  events. This includes employees, independent contractors,
                  consultants, affiliates, successors, and assignees
                  using/accessing the Platform/Services. Data Controllers are
                  responsible for data safekeeping, transportation, storage, and
                  the implementation of business rules. They are the entities
                  determining the purposes and means of processing Personal
                  Data. If the purposes and means of processing are established
                  by national or community laws or regulations, the controller
                  or specific criteria for nominating the controller may be
                  designated by such laws.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Customer Personal Data" denotes any Personal Data shared by
                  the Customer with or authorised for access, storage, hosting,
                  modification, sharing, deletion, and further Processing by
                  FitFunda and/or its Affiliates for performing the Services.
                  fontSize: "11pt",
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Personal Data" encompasses information related to a Data
                  Subject, where an identifiable natural person is one who can
                  be directly or indirectly identified, particularly by
                  reference to an identifier such as a name, an identification
                  number, location data, an online identifier, or one or more
                  factors specific to the physical, physiological, genetic,
                  mental, economic, cultural, or social identity of that natural
                  person. This includes any special categories of Personal Data
                  as defined in Art. 9 of the GDPR.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Attendees" denotes the clients and all individuals attending
                  or participating in events organised by the Customer via
                  FitFunda Services.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  “Sponsors” refers to the organisations who have an agreement
                  with the Customer to display the organisation’s relevant
                  information inside the Platform during the event for certain
                  activities, including , but not limited to branding and lead
                  generation .Sponsors shall only have access to add/modify
                  their relevant data as defined by the Customer in the event
                  creation flow.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                    fontSize: "11pt",
                  }}
                >
                  "Data Processor" refers to a natural or legal person, public
                  authority, agency, or other entity processing Personal Data on
                  behalf of the Data Controller and in accordance with the Data
                  Controller's instructions, typically for specific purposes and
                  services accessible to the Data Controller.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                    fontSize: "11pt",
                  }}
                >
                  "Commercial Purposes" and "Sell" bear the meanings ascribed to
                  them in section 1798.140 of the CCPA.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Applicable Data Protection Laws" collectively includes all
                  data protection laws and regulations, encompassing United
                  States federal and state privacy laws, European Union Data
                  Protection Laws, including but not limited to the General Data
                  Protection Regulation ("GDPR"), and any other laws concerning
                  data protection worldwide that may apply to the processing of
                  Personal Information (also known as “Personal Data”) under the
                  Privacy Policy.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Data Protection Officer" (DPO) signifies, where applicable,
                  an individual responsible for the protection of Personal Data
                  at FitFunda or Vrattanta.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Sub-processor" or "Third-Party Service Provider" means any
                  natural or legal person, public authority, agency, or body
                  appointed by or on behalf of the Processor, or by or on behalf
                  of an existing Sub-processor, to process Personal Data on
                  behalf of the Controller, as defined in Art. 4(10) and further
                  explained in 28(4) of GDPR.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  "Supervisory Authority" carries the meaning assigned to it
                  under the GDPR.
                </p>
              </li>
            </ul>
            <p
              className="s2"
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              What Information We Collect About You
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              When connecting to and navigating our Platform, we will gather
              information about you to enhance the quality of our Services as
              part of FitFunda’s regular business operations. In our capacity as
              a Data Controller, FitFunda collects this data from you for
              various purposes, including product demonstrations, marketing
              activities, job vacancies and career applications, or other
              purposes when you provide explicit consent for such use.
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "29pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Below is a general list of categories of Personal Information that
              we collect from you: Types of Personal Data Collected by FitFunda
              as a Data Controller:
            </p>
            <ol id="l2">
              <li data-list-text={1}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Contact Information via the "Request for Demo" Form: First
                  name, last name, business email address, phone number, and
                  company name.
                </p>
                {/* <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "41pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            ></p> */}
              </li>
              <li data-list-text={2}>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Professional Information for Job Vacancies/Career
                  Applications: First name, last name, business email address,
                  phone number, and company name.
                </p>
              </li>
              <li data-list-text={3}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Customer Account Creation: First and Last Name, Email (Social
                  Login service provided) , Phone Number , Address , City,
                  State, Country , Pincode, GST number ( If applicable) ,
                  Identity Proof and Number (Driving License etc. , if required)
                  , Bank Account Details (If required)
                </p>
              </li>
              <li data-list-text={4}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Information Technology-Related Data:
                </p>
                <ul id="l3">
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Information derived from your use of FitFunda’s website,
                      mobile applications, and other connected devices,
                      including:
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Internet Protocol (IP) address, geolocation data, browser,
                      operating systems, and device ID.
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Data collected by cookies, which may include analytics
                      information and details about the date and time of your
                      requests (please refer to the Cookie Section below for
                      more information).
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Metadata that allows us to improve the user experience in
                      FitFunda, as applicable and with your consent as required
                      under Applicable Data Protection Laws.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={5}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  De-Identified and Anonymized Information: We may use
                  anonymized Personal Information to enhance our services.
                </p>
              </li>
              <li data-list-text={6}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Integration of Sub-processors/Third-Party Service Provider(s):
                  By proceeding with the integration of Third-Party Service
                  Provider(s), you grant us permission to collect Personal Data
                  already associated with these providers. We are not
                  responsible for any agreements you have with
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Third-Party Service Provider(s).
                </p>
              </li>
              <li data-list-text={7}>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Transactional/Payment Details: This includes Personal Data
                  related to payments and other payment-related information made
                  available to us. We do not store card details on our FitFunda
                  or Vrattanta servers, and credit and debit card payments are
                  processed by Third-Party Service Provider(s) on their secure
                  payment servers, with all card details fully encrypted and
                  stored by them. Other transactional/payment details collected
                  that are not Personal Data, such as company bank account
                  details (e.g., Account Number, IFSC, UPI ID, Swift Code),
                  pertain to the customer's company transaction/payment details
                  and are not considered Personal Data under Applicable Data
                  Protection Laws.
                </p>
              </li>
              <li data-list-text={8}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Attendee Details : In the event of Customers using FitFunda
                  solely for “FitFunda Event Registrations” , we will collect
                  and process Attendee data. We may use this data for enhancing
                  user experience, curating specific events based on attendee
                  interests and promotional activities of other events on the
                  “FitFunda Registrations” marketplace.
                </p>
              </li>
              <li data-list-text={9}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  As a Data Processor, FitFunda offers tailored/customised
                  service options to customers that include built-in features
                  within FitFunda Services. Customers may choose to collect the
                  Personal Data of attendees who will be participating in events
                  organised by the customer on our Platform. This collection is
                  carried out in accordance with the customer's privacy policy.
                </p>
              </li>
              <li data-list-text={10}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Customers may collect the following Personal Data of
                  attendees:
                </p>
                <ul id="l4">
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Types of Personal Data Collected by FitFunda as a Data
                      Processor During an Event:
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: "3pt",
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Attendee account creation information, such as first name,
                      last name, email address (Mandatory) and, other optional
                      fields that includes phone number, Title, Organisation,
                      Location, Biography, Social media handles of linkedin,
                      instagram and twitter
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Registration and ticketing information, including name and
                      email address.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Registration Form details , which may include both
                      Personal information (e.g., name, email address, phone
                      number, designation) and non-personal information ( Such
                      as choosing T-Shirt Size) as customised by the customer or
                      event organiser for the event.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Event App-related data, including social profile logins
                      (e.g., Facebook and LinkedIn) and certain permissions,
                      such as access to media files, storage, and contacts.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Event calendar integration to ensure seamless access to
                      events and provide reminders.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={11}>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  The purpose for the collection of Personal Data when the
                  customer acts as the Data Controller is determined by the
                  customer. FitFunda acts solely on the instructions of the
                  customer and does not control the data or define the purpose
                  of its collection.
                </p>
              </li>
              <li data-list-text={12}>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  In certain situations, the customer may opt to collect
                  sensitive Personal Information about you. When this occurs, we
                  implement enhanced safeguards to protect your privacy. Any
                  collection of sensitive Personal Data is entirely at the
                  discretion of the customer.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  Purpose For Collecting Your Information
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  When you connect to our Platform and utilise our Services, we
                  gather Personal Information about you for the following
                  purposes:
                </p>
                <ul id="l5">
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Assisting Customers with Product and Service Inquiries: We
                      access your Personal Data that you have provided to us
                      through the "request a demo" form available on our
                      Platform when you: Request a demo or Subscribe to our
                      solution or Agree to receive our newsletters and webinars
                      for updates on products or to follow us and view our
                      initiatives or We may need to identify you when you
                      interact with us to respond to your inquiries and address
                      any questions you may have within the context of a
                      pre-contractual or contractual relationship with us.
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Contacting Purposes: We collect and utilise Personal Data
                      of: Business partners, including their staff and third
                      parties with whom you collaborate in the context of the
                      services you provide to us or within a business
                      relationship and/or partnership. Customers, when we retain
                      your information to manage the contract with you regarding
                      the products or services you request from us. This
                      includes the collection of Personal Information before,
                      during, and after we enter into a contract with you, your
                      organisation, and your staff.
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Enhancing Our Customer Service and Product: During events,
                      we may access your Personal Data to: Serve the session
                      recording to Google's Speech-to-Text service to generate a
                      transcript of a session or Improve our customer service
                      and FitFunda services. We also capture a range of
                      meta-data related to activities of the attendees in our
                      platform including , but not limited to activities such as
                      pages visited, collaterals downloaded, Questions asked in
                      events, Poll submissions and 1-1 Chats with other
                      attendees
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Profiling: To gain better insights into our audience and
                      customers, we utilise specific Personal Information about
                      you, which includes data related to: Your country,
                      determined, for instance, via your IP address and region,
                      Your language, Your email domains, The number of pages you
                      visit, The number of emails you receive and when you open
                      them, Your communications with us, such as instant
                      messages, phone calls, and emails exchanged with us. We
                      will only use aggregated information for this purpose. For
                      further details, please refer to our Cookie Policy.
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "30pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              In addition to the purposes mentioned above, we also collect
              Personal Information to provide you with a secure, seamless,
              efficient, and personalised experience on the Platform. We process
              your Personal Information and non-Personal Information to create,
              develop, operate, deliver, and enhance the Platform and the
              Services. We will not collect additional categories of Personal
              Information or use the Personal Information we collect for
              significantly different, unrelated, or incompatible purposes
              without obtaining your explicit consent.
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            ></p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Legal Basis For Processing Your Information
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "30pt",
                textIndent: "0pt",
                textAlign: "justify",
                fontSize: "11pt",
              }}
            >
              FitFunda Global Data Processing Policy
            </p>
            <p
              style={{
                paddingTop: "1pt",
                paddingLeft: "30pt",
                textIndent: "0pt",
                textAlign: "justify",
                fontSize: "11pt",
              }}
            >
              This Policy is applicable globally and extends to all individuals,
              regardless of their place of residence. In this section, we
              elucidate the legal justifications, often referred to as the
              "legal basis," for the processing of Your Personal Information in
              connection with our primary processing activities.
            </p>
            <p
              style={{
                paddingLeft: "30pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Please take note that the legal requirements governing the use of
              specific legal bases to justify the processing of Your Personal
              Information, including cross-border transfers, may vary depending
              on Your country of residence. For instance, the GDPR mandates
              obtaining consent to process Personal Information when interacting
              with Us. Thus, we will seek Your consent before processing such
              Personal Information in jurisdictions where this is required. In
              the case of International Data transfers, which may be required
              when we partner with third party vendors, we only partner with
              third-parties who adhere to the standard contractual clauses for
              international data transfer as outlined by GDPR.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
            <p
              style={{
                paddingLeft: "18pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Below, we provide an explanation of FitFunda's legal bases for
              processing Your Personal Information: Legal Basis for Processing
              Activities and Purposes of Personal Information Use:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
            <ol id="l6">
              <li data-list-text={1}>
                <p
                  style={{
                    paddingLeft: "17pt",
                    textIndent: "-12pt",
                    textAlign: "justify",
                    fontSize: "11pt",
                  }}
                >
                  Contractual Basis:
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                    fontSize: "11pt",
                  }}
                >
                  As one of our customers or business partners, we will retain
                  and store your personal or professional contact details to
                  facilitate interaction, provide you with relevant offers,
                  manage the (pre-) contract or your user account, and for
                  administrative or billing purposes.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
              </li>
              <li data-list-text={2}>
                <p
                  style={{
                    paddingLeft: "17pt",
                    textIndent: "-12pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Consent Basis:
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  We collect information about you when you navigate our
                  websites and utilise various features, when you submit queries
                  or engage with us.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  We use your information for marketing and sales purposes,
                  including proposing similar offers, informing you about
                  upcoming products, and sending newsletters, as well as for
                  statistical analysis.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Depending on your country of residence, we may be required to
                  obtain your prior consent for actions such as:
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Subscribing to our newsletters.
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Sharing or disclosing your Personal Data to Sub-processors
                  located outside your country of residence in nations that do
                  not offer the same level of privacy and data protection as
                  your home country.
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Using forms when creating your user account or when employing
                  cookies or similar technologies.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
              </li>
              <li data-list-text={3}>
                <p
                  style={{
                    paddingLeft: "17pt",
                    textIndent: "-12pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Compliance with Applicable Data Protection Laws and Legal
                  Statutes:
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  In some limited instances, we may be obligated to retain
                  certain limited Personal Information about you for an extended
                  duration, such as for tax or accounting purposes.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  This Policy ensures compliance with global data protection
                  regulations and serves as a framework for the lawful and
                  ethical processing of Personal Information, prioritising the
                  protection of individual privacy rights.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                    fontSize: "16pt",
                  }}
                >
                  Retention, Return And Deletion Of Your Personal Information
                </p>
                <h1
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                    fontWeight: "20pt",
                  }}
                >
                  FitFunda Data Retention Policy
                </h1>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  FitFunda will retain Your Personal Data only for the duration
                  necessary to fulfil the purposes outlined in this Policy. For
                  users who have provided their Personal Data on our Platform,
                  we will retain the collected Personal Data for as long as
                  required to provide our Services through the Platform and to
                  meet any
                </p>
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  legal obligations (e.g., compliance with applicable legal,
                  tax, or accounting requirements and for archival purposes).
                  Customer Personal Data will be retained by FitFunda for 60
                  months from the date of termination of the agreement(s) with
                  our Customers. This retention period is solely for repurposing
                  and/or reusing Customer Data for any future events hosted by
                  the Customer on the Platform. FitFunda will not utilise this
                  data for any purpose other than retaining it for the sake of
                  convenience to the Customer. After the conclusion of the
                  specified retention period, FitFunda will automatically delete
                  all data provided by the Customer from the FitFunda database,
                  unless otherwise requested by the customer or any other
                  contractual obligations which will be dealt with on a case to
                  case basis by FitFunda.
                </p>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "29pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Below, are details about data retention, legal basis, and
                  purpose for each category of Personal Data: Platform User
                  Data, including name:
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Purpose:To provide and enhance the FitFunda Platform and
                  Services.
                </p>
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "120%",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Legal Basis: Consent (e.g., cookies, newsletters, providing
                  demos) and Legitimate Interest (e.g., responding to queries
                  about FitFunda products and services).
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Customer Personal Data:
                </p>
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "120%",
                    textAlign: "left",
                    fontSize: "11pt",
                    fontSize: "11pt",
                  }}
                >
                  Purpose:To engage with You and provide related offers as a
                  Customer, manage the (pre-) contract or Your user account, and
                  for administrative or billing purposes.
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "120%",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Legal Basis:Contract (e.g., providing products and services
                  for fulfilment, administrative or billing purposes),
                  Legitimate interest of FitFunda, and Consent
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Customer Data (as defined by the Customer):
                </p>
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "120%",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Purpose:As defined by the Customer.
                </p>
                <p
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "120%",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Legal Basis:FitFunda acts as the processor, and the legal
                  basis is defined by the Customer, relying on FitFunda's legal
                  and contractual obligation to its Customer.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Data Retention, Return, and Deletion:
                </h1>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Where FitFunda lacks a legitimate business interest to
                  continue processing Your Personal Data or if You request
                  deletion and/or return of Your Personal Data, we will either
                  irrevocably delete, anonymize it, or, if not possible (e.g.,
                  if Personal Data is stored in secured archives), securely
                  store and isolate Your information from further processing
                  until deletion becomes feasible. We will employ advanced
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  industry-certified technology and other methods to protect
                  Your data and mitigate risks, including obfuscation, blanking,
                  or encryption.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  For Attendees' data provided to us by our Customers, we will
                  retain the data for 60 months after the contract's expiration,
                  unless the Customer requests earlier deletion or return.
                  Customers can request FitFunda to delete all data via a
                  written request or instruction, for instance, when the data is
                  no longer necessary for the specified processing purposes.
                  FitFunda will address such requests within 15 days of receipt.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Upon the expiration of the retention period, all Customer and
                  Attendee data will be automatically deleted by FitFunda from
                  all FitFunda databases, including those of Third-Party Service
                  Providers.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  With Whom Do We Share Your Personal Information
                </p>
                <ul id="l7">
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingTop: "8pt",
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      FitFunda Employees: Access to Your Personal Data is
                      restricted to duly authorised FitFunda employees who
                      require such access on a need-to-know basis.
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "30pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Disclosure of Personal Data to Third-Party Service
                      Providers: In certain limited situations, authorised
                      Third-Party Service Providers external to our organisation
                      may have access to Your Personal Data. These scenarios may
                      include:
                    </p>
                    <ul id="l8">
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Third-Party Service Providers who offer their services
                          to us for the management of the Platform (e.g., IT
                          services for maintenance or in case of technical
                          issues).
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          For our internal business operations, including
                          Platform access and usage administration, data
                          analysis, secure user identification, improvement of
                          our Services, assessment of marketing campaign
                          effectiveness, billing purposes, and overall business
                          operation.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                          }}
                        >
                          Other authorised Third-Party Service Providers, such
                          as auditors or data centres, or when required by law,
                          court orders, legal claims defence, or investigations
                          by supervisory authorities.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          You explicitly consent to the disclosure of Your
                          Personal Data to relevant Third-Party Service
                          Providers whose services FitFunda utilises for various
                          purposes, including but not limited to marketing,
                          analysis, advertising, contract management, billing,
                          consent management, data subject rights management,
                          and cloud service management.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          When we engage Third-Party Service Providers, we
                          establish agreements with them to ensure that the
                          processing of Your Personal Data aligns with our
                          instructions, maintaining confidentiality, security,
                          transparency, and adherence to privacy rights, all in
                          compliance with Applicable Data Protection Laws. We do
                          not partner with any third-party Services who do not
                          meet the terms of FitFunda’s privacy policy.
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                          <br />
                        </p>
                        <p
                          className="s2 "
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "0pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "16pt",
                          }}
                        >
                          Cookies And Tracking Technologies We Use
                        </p>
                        <p
                          style={{
                            paddingTop: "8pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Cookies or similar tracking technologies may be used
                          on the site to automatically collect certain
                          information about Your device. We usually do not
                          collect Your Personal Data, however, should it happen,
                          this may include Personal Data as explained in this
                          section. We may collect the following 3 types of
                          cookies from you when you visit our website or use the
                          platform :
                        </p>
                        <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Essential Cookies. Essential Cookies are required for
                      providing you with features or services that you have
                      requested. For example, certain Cookies enable you to log
                      into secure areas of our Site. Disabling these Cookies may
                      make certain features and services unavailable.
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Functional Cookies. Functional Cookies are used to record
                      your choices and settings regarding our Site, maintain
                      your preferences over time and recognize you when you
                      return to our Site. These Cookies help us to personalise
                      our content for you, greet you by name and remember your
                      preferences (for example, your choice of language or
                      region).
                    </p>
                  </li>
                  <li data-list-text="●">
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "-18pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      Performance/Analytical Cookies. Performance/Analytical
                      Cookies allow us to understand how visitors use our Site
                      such as by collecting information about the number of
                      visitors to the Site, what pages visitors view on our Site
                      and how long visitors are viewing pages on the Site.
                      Performance/Analytical Cookies also help us measure the
                      performance of our advertising campaigns in order to help
                      us improve our campaigns and the Site’s content for those
                      who engage with our advertising. For example, Google Inc.
                      (“Google”) uses cookies in connection with its Google
                      Analytics services. Google’s ability to use and share
                      information collected by Google Analytics about your
                      visits to the Site is subject to the Google Analytics
                      Terms of Use and the Google Privacy Policy. You have the
                      option to opt-out of Google’s use of Cookies by visiting
                      the Google advertising opt-out page at
                      <a
                        href="https://policies.google.com/technologies/ads"
                        target="_blank"
                      >
                        www.google.com/privacy_ads.html
                      </a>
                      or the Google Analytics Opt-out Browser Add-on at
                      <a
                        href="https://tools.google.com/dlpage/gaoptout/"
                        target="_blank"
                      >
                        https://tools.google.com/dlpage/gaoptout/.
                      </a>
                    </p>
                  </li>
                </ul>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  Data Minimization
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  To the extent allowed and practicable, and in order to
                  safeguard your privacy rights, FitFunda will undertake
                  reasonable measures to eliminate or anonymize data that could
                  identify you directly or indirectly. We will also minimise, to
                  the greatest extent possible, the volume of Personal
                  Information we utilise, transmit, or share with Third-Party
                  Service Providers.
                </p>
                <p
                  className="s2"
                  style={{
                    paddingTop: "3pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  Unsubscribe And Withdrawal Of Consent
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  If FitFunda processes your Personal Data based on your
                  consent, you have the right and can choose to withdraw your
                  consent at any time. Please notify FitFunda of this withdrawal
                  by contacting us{" "}
                  <span
                    style={{
                      color: "#1154CC",
                      fontFamily: "Arial, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      fontSize: "11pt",
                    }}
                  >
                    privacy@FitFunda.com
                  </span>
                  .
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  If you choose not to provide your consent or later decide to
                  withdraw it, we kindly request that you refrain from accessing
                  the Platform and using our Services. Additionally, we reserve
                  the right to withhold Services or information on the Platform
                  in such cases of consent withdrawal. Upon withdrawal of your
                  consent, FitFunda may either delete your information (whether
                  personal or otherwise) or de-identify it to ensure it becomes
                  anonymous and unattributable to you, except to the extent
                  detailed in the "LEGAL BASIS FOR PROCESSING YOUR INFORMATION"
                  section of this Policy.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  When FitFunda acts as a Data Processor, we solely process
                  Personal Data based on the instructions provided by our
                  Customers and have no direct control or ownership of the
                  Personal Data we process. Customers are responsible for
                  adhering to any regulations or laws that necessitate notice,
                  disclosure, or consent before transferring data to FitFunda
                  for further processing purposes. Attendees of an event seeking
                  to access, correct, or delete data should direct their
                  requests to the Customer. If a Customer requests FitFunda to
                  delete, rectify, or access an Attendee's Personal Data to
                  comply with Applicable Data Protection Laws, FitFunda will
                  process this request within the specified timeframe as
                  required by the Applicable Data Protection Laws.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Please note that FitFunda will not process a Data Subject
                  request to alter information that may render it incorrect,
                  fraudulent, misrepresented, or in violation of any applicable
                  law or legal statute. In such instances, we will inform the
                  Customer about the legal obligations that prevent us from
                  fulfilling the request.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  How Do We Protect Information About You ?
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  We have implemented appropriate technical and organisational
                  controls to safeguard Your Personal Data, which we possess,
                  with the aim of preventing unauthorised Processing, data loss,
                  disclosure, use, alteration, or destruction. When deemed
                  necessary, we employ protective techniques such as encryption,
                  anonymization, pseudonymization, de-identification, and other
                  technologies that enhance the security of information related
                  to You. These measures include mechanisms for restoring access
                  to Your Personal Information. Additionally, we ensure that our
                  Third-Party Service Providers adhere to reasonable and
                  recognized data privacy, confidentiality, integrity,
                  availability, and security standards.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  We regularly conduct assessments and reviews of our
                  technologies and processes, including evaluations of our
                  business partners and vendors, to maintain the effectiveness
                  of our security controls. Furthermore, we commit to
                  anonymizing Your Personal Data or deleting it when it is no
                  longer required for the original purpose for which we
                  collected such Personal Information from You.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "12pt",
                  }}
                >
                  Security Measures Implemented by FitFunda to Protect Your
                  Personal Information:
                </h1>
                <p
                  style={{
                    paddingTop: "1pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  We have configured our systems to apply industry-standard
                  information security measures and have adopted recognized
                  security frameworks to safeguard Your Personal Information.
                  Our technical and organisational security measures are
                  comprehensive and designed to prevent any form of loss,
                  destruction, unauthorised access or use of our systems,
                  software, or Personal Data. We also take measures to prevent
                  unauthorised alterations to our systems, software, or Personal
                  Data.
                </p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  In addition to these precautions, we maintain strict access
                  controls for Your Personal Data, limiting access only to
                  employees, contractors, and other Third-Party Service
                  Providers with a legitimate business need to access this
                  information. When engaging Third-Party Service Providers to
                  assist us with information security measures, we ensure their
                  commitment to adhering to stringent security, data protection,
                  and privacy requirements. These requirements not only align
                  with industry best practices but also comply with Applicable
                  Data Protection Laws to ensure the utmost confidentiality,
                  integrity, and availability of Your Personal Data.
                </p>

                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "16pt",
                  }}
                >
                  Cross Border Transfers
                </p>
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  When we receive information about You, whether it's for
                  applying to FitFunda or engaging in information exchange with
                  us, we will collect, process, and store Your Personal Data in
                  accordance with Applicable Data Protection Laws and as
                  outlined in this Policy. In most cases, You voluntarily
                  provide this Personal Data to us through our Platform, forms,
                  and emails, typically for the purpose of entering into a
                  contract with FitFunda or based on Your explicit consent.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  When You exchange information with other Third-Party Service
                  Providers, it's important to acknowledge that Personal Data
                  concerning You or other data subjects may be transferred
                  outside Your country of residence. This may necessitate the
                  implementation of appropriate, supplementary, or additional
                  safeguards to comply with Applicable Data Protection Laws.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                <p
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontSize: "11pt",
                  }}
                >
                  Please be aware that FitFunda is committed to maintaining an
                  appropriate level of protection and deploying all necessary
                  safeguards when transferring data that includes confidential
                  information and/or Personal Data. Additionally, for transfers
                  to countries that do not guarantee an adequate level of data
                  protection, FitFunda will ensure that the transfer occurs
                  through one of the following mechanisms:
                </p>
                <ol id="l9">
                  <li data-list-text="i)">
                    <p
                      style={{
                        paddingLeft: "14pt",
                        textIndent: "-9pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      In alignment with the Swiss-U.S. and EU-U.S. Privacy
                      Shield Framework and Principles issued by the
                    </p>
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      U.S. Department of Commerce (referred to as the "Privacy
                      Shield Principles"), or
                    </p>
                  </li>
                  <li data-list-text="ii)">
                    <p
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontSize: "11pt",
                      }}
                    >
                      By utilising the Standard Contractual Clauses as set forth
                      and approved by the European Commission.
                    </p>
                    <p
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "justify",
                        fontSize: "11pt",
                      }}
                    >
                      In the event that European Union authorities or courts
                      determine that the Privacy Shield is an unsuitable basis
                      for such transfers, FitFunda, its Customers, and, as
                      applicable, FitFunda Sub-processors, agree to promptly
                      execute an approved Standard Contractual Clause to govern
                      these transfers.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "justify",
                        fontSize: "16pt",
                      }}
                    >
                      Other Information About Your Privacy
                    </p>
                    <ul id="l10">
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingTop: "8pt",
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Links to Other Websites: Our Service may include links
                          to websites operated by third parties that are not
                          under our control. Clicking on a third-party link will
                          take you to that third party's website. We strongly
                          recommend reviewing the privacy policy of each website
                          you visit and understanding all the risks associated
                          with this action. We have no authority over, and
                          assume no responsibility for, the content, privacy
                          policies, or practices of third-party websites or
                          services.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Children's Privacy: Our Service is not designed for
                          individuals under the age of 18 ("Children"). We do
                          not knowingly collect Personal Information from anyone
                          under 18 on a voluntary basis. If you are a parent or
                          guardian and are aware that your child has provided
                          Personal Information to us, please contact us. If we
                          discover that we have collected Personal Information
                          from children without proper parental, judicial, or
                          guardian consent, we will take steps to remove that
                          information from our data servers.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Personal Data Breaches and Incidents: We have
                          established procedures and safeguards to promptly
                          identify, assess, investigate, and report Personal
                          Data breaches. Our procedures are robust and are
                          regularly communicated to our staff, who receive
                          ongoing training in industry-certified best IT
                          security practices. We are committed to maintaining
                          the confidentiality, integrity, and accessibility of
                          Your Personal Data at all times.
                        </p>

                        <p
                          className="s2"
                          style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "16pt",
                          }}
                        >
                          Your Rights
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingTop: "8pt",
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          User Rights and Complaints: As a user of our Services
                          and Platform, and subject to the laws of your country
                          of residence, you may have the right to exercise your
                          rights and file complaints with the competent data
                          protection authority or supervisory authority.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Access, Restriction, Rectification, Erasure: Under
                          applicable data protection laws, you have the right to
                          control your data. You may request a copy of
                          information about you held by us. Additionally, you
                          have the right to rectify, restrict, or erase/delete
                          such information, subject to limited legal and
                          regulatory restrictions.
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Objection to Processing and Additional Rights:
                          According to applicable data protection law, you can
                          formally object to the processing of your Personal
                          Data. In certain circumstances, you have the
                          additional right to restrict or suspend aspects of the
                          processing of your information or request a copy of
                          your Personal data in a digital and machine-readable
                          format (portability).
                        </p>
                      </li>
                      <li data-list-text="●">
                        <p
                          style={{
                            paddingLeft: "41pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Objection to Direct Marketing: You have the right to
                          object to certain processing of your information,
                          including processing for direct marketing purposes. If
                          we process your information based on our legitimate
                          interests, you can object to this processing, unless
                          there are compelling legitimate grounds or legal
                          reasons for such processing. You can always object and
                          opt-out of future marketing messages for our own
                          services using the unsubscribe link provided in such
                          communications.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              In Exercising Your Rights
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              You have the right to exercise your rights of access,
              rectification, restriction of processing, and erasure by
              submitting a request through the provided channels.
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Please be aware that we may request you to verify your identity
              before we can respond to your requests. Upon receiving a request,
              we will make every effort to respond promptly and within the time
              frame specified by applicable data protection laws.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Changes To This Privacy Policy
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              We may update this Policy from time to time. You are advised to
              review the updated Policy periodically for any changes. Changes to
              this Policy are effective when they are posted on this page and
              any modifications can be ascertained by referring to the date that
              is displayed at the top of the page marked as, “ Last updated”.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Contact Details
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify",
                fontSize: "11pt",
              }}
            >
              We provide easily accessible information via our Platform or on
              request. If You have any questions or requests related to this
              Policy or Your Personal Information, please contact us at the
              following contact details. We will revert back to you within 2
              Business Days (48 Hrs).
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Vrattanta Endurance and Sports, D2-502, Lunkad Goldcoast Viman
              nagar,
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Pune - 411014
            </p>
            <p
              style={{
                paddingTop: "0pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Maharashtra ,India
            </p>
            <p
              style={{
                paddingTop: "0pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              <a
                href="mailto:privacy@vrattanta.com"
                className="s4"
                target="_blank"
              >
                Email: privacy@vrattanta.com
              </a>
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Data Protection Officer
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              Name: Sanjib Paul
            </p>
            <p
              style={{
                paddingTop: "0pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              <a
                href="mailto:sanjib@vrattanta.com"
                className="s4"
                target="_blank"
              >
                Email: sanjib@vrattanta.com
              </a>
            </p>
            <a
              href="#"
              onClick={toggleShowMore}
              style={{
                color: "#1154CC",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              View less...
            </a>
          </>
        )}

        {!showMore && (
          <p
            style={{
             
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "8pt",
              textAlign:"left"
            }}
          >
            <a href="#" onClick={toggleShowMore} style={{ fontSize: "17px"}}>
              View more...
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
