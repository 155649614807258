import { Link } from "react-router-dom";
import SliderEvent from "./SliderEvent.jsx";
import { GetCityEventData } from "../../services/eventAPI.jsx";
import { IsMobile } from "../../utils/commonFun.jsx";
import { useEffect, useState } from "react";

const SliderCityEvent = (props) => {
  const cityId = props.city[0];
  const cityName = props.city[1];
  const refCall = "SliderRecommededEvent";

  //check screen width
  let finish = 3;
  useEffect(() => {
    let isMobile = IsMobile();
    if (isMobile === true) {
      finish = 1;
    }
  });

  //slider data
  const sliderProp = {
    leftInActive: "disabled",
    leftActive: "",
    rightInActive: "disabled",
    rightActive: "",
    finish: finish,
    noOfImageRotate: 1,
  };

  const [eventData, setEventData] = useState("");

  useEffect(() => {
    const getAllEventByCity = async () => {
      try {
        const eventsByCityResponse = await GetCityEventData(cityId);
        if (eventsByCityResponse.success === true && eventsByCityResponse?.data?.length > 0) {
          setEventData(eventsByCityResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllEventByCity();
  }, []);

  return (
    <>
   {eventData && cityName &&  (
        <>
          <div className="row mx-2">
            <div className="col text-start cat-name ml-2">
              <Link to={"/event/" + cityName + "/" + cityId}>
                <b>Events</b> in {cityName}
              </Link>
            </div>
            {eventData?.data?.length > 3 && (             
              <div className="col ms-auto text-end">
                <Link to={"/event/" + cityName + "/" + cityId}>
                  <span className="badge text-bg-light border view-text">
                    View All
                  </span>
                </Link>
              </div>             
            )}
          </div>
          <SliderEvent
                sliderProp={sliderProp}
                eventData={eventData && eventData.data}
              />
        
          <div
            className="row text-end mdnViewAll"
            style={{
              marginTop: "-30px",
              marginBottom: "10px",
              display: "none",
            }}
          >
            <Link to={"/event/" + cityName + "/" + cityId + "/#section1"}>
              <span className=" view-text">View all event in {cityName}</span>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default SliderCityEvent;
