import useFetch, {
  useFetchGetRequest,
  useFetchGetRequestWithToken,
  useFetchMultipart,
  useFetchMultipartWithToken,
} from "./api";

export const GetCityEventData = (cityId) => {
  const cityIdIs = parseInt(cityId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-by-city/${cityIdIs}`;

  const eventCityData = useFetchGetRequest(apiURL);
  return eventCityData;
};

export const GetCategoryEventData = (categoryId) => {
  const categoryIdIs = parseInt(categoryId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-by-categoryId/${categoryIdIs}`;

  const eventCityData = useFetchGetRequest(apiURL);
  return eventCityData;
};

export const GetDraftEventData = (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-draft-events`;

  const draftEvents = useFetchGetRequestWithToken(apiURL, token);
  return draftEvents;
};

export const GetOnGoingEventData = (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-ongoing-events`;

  const eventCityData = useFetchGetRequestWithToken(apiURL, token);
  return eventCityData;
};

export const GetPastEventData = (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-past-events`;

  const eventCityData = useFetchGetRequestWithToken(apiURL, token);
  return eventCityData;
};

export const GetRecommendedEventData = () => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventdata.json`;
  return useFetch(apiURL);
};

export const GetEventDetailsData = (eventId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-by-eventId/${eventId}`;

  const eventDetailsByEventId = useFetchGetRequest(apiURL);
  return eventDetailsByEventId;
};

//CartEvent
export const GetCartEvents = (cartEvents) => {
  // const data= useState();
  // const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  //const data = useFetch(apiURL);
  const data = cartEvents;
  console.log(data);
  return data;
};

export const SaveAddToCartEvent = (cartEvents) => {
  // const data= useState();
  // const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  //const data = useFetch(apiURL);
  const data = cartEvents;
  console.log(data);
  return data;
};

export const DeleteFromCartEvent = (eventID) => {
  // const data= useState();
  const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  const data = useFetch(apiURL);
  console.log(data);
  return data;
};
//----------------

//UserDetail
export const GetUserDetails = (userDetails) => {
  // const data= useState();
  // const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  //const data = useFetch(apiURL);
  const data = userDetails;
  console.log(data);
  return data;
};

export const SaveAddToUserDetail = (userDetails) => {
  // const data= useState();
  // const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  //const data = useFetch(apiURL);
  const data = userDetails;
  console.log(data);
  return data;
};

export const DeleteFromUserDetail = (eventID) => {
  // const data= useState();
  const apiURL = `${process.env.REACT_APP_EVENT_MS}tempData/eventproductdata.json`;
  const data = useFetch(apiURL);

  return data;
};

export const GetSearchEventData = (searchText, searchFilter) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-search-events/`;

  const formData = new FormData();
  formData.append("search", JSON.stringify(searchFilter));

  const eventData = useFetchMultipart(apiURL, formData);

  return eventData;
};

//Start-Event-Sale-Table
const Mode = "portal";
export const GetAllCountByUserId = async (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-counts`;
  const formData = new FormData();
  formData.append("mode", Mode);
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetAllEventByUserId = async (token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-all-events`;
  const formData = new FormData();
  formData.append("mode", Mode);
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetEventSalesByUserId = async (token, eventId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-sales`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetAllParticipantsByEventId = async (token, eventId) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-participants`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetAllParticipantsDetailsByEventId = async (token, eventId) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-event-participants-details`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetParticipantsDataByID = async (token, participantId) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("participantId", participantId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-participant-data`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
export const GetParticipantsPaymentByID = async (
  token,
  eventId,
  participantId,
  txnId,
  ocId
) => {
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("participantId", participantId);
  formData.append("eventId", eventId);
  formData.append("txnId", txnId);
  formData.append("ocId", ocId);
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-participant-payment`;
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};

export const EventActiveOrDeactiveByUserId = async (token, eventId, userId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/cancel-published-event`;
  const formData = new FormData();
  formData.append("mode", Mode);
  formData.append("eventId", eventId);
  formData.append("userId", userId);
  const eventData = useFetchMultipartWithToken(apiURL, formData, token);
  return eventData;
};
//End-Event-Sale-Table

export const GetOrganizerLogo = async (userId) => {
  const apiURL =
    `${process.env.REACT_APP_USER_MS}org/get-business-logo/` + userId;
  const data = useFetchGetRequest(apiURL);

  return data;
};

export default GetCityEventData;
