import * as React from "react";

const Footer = () => {
  return (
    <>
      <div class="footer row mx-2 border-top pt-4">
        <div class="col text-start footer-text">
          © {new Date().getFullYear()} FitFunda | All rights reserved.
        </div>
        <div class="col text-end">
          <div class="d-flex flex-row mb-3 justify-content-end">
            <div class="p-2 social-icon">
              <a href="">
                <i class="bi bi-facebook"></i>
              </a>
            </div>
            <div class="p-2 social-icon">
              <a href="">
                <i class="bi bi-twitter-x"></i>
              </a>
            </div>
            <div class="p-2 social-icon">
              <a href="">
                <i class="bi bi-instagram"></i>
              </a>
            </div>
            <div class="p-2 social-icon">
              <a href="">
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
