import { Routes, Route, useLocation } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./App.css";
import Home from "./pages/home/Home";
import NoPage from "./pages/nopage/NoPage";
import Category from "./pages/event/EventCagetory";
import Footer from "./components/footer/Footer";
import ViewEventDetail from "./pages/event/ViewEventDetails";
import EventTicketOptions from "./pages/event/EventTicketOptions";
import EventCheckOut from "./pages/event/EventCheckout";
// import { AuthProvider } from "./context/user/UserContext";
// import { CityProvider } from "./context/city/CityContext";
import SearchAll from "./pages/search/SearchAll";
import Events from "./pages/event/Events";
import CreateEvent from "./pages/createEvent/CreateEvent";
import ParticipantInfo from "./pages/participant/ParticipantInfo";
import ViewOrganizerProfile from "./pages/profile/view/ViewOrganizerProfile";
import PastEventList from "./pages/pastEvent/PastEventList";
import DraftEvent from "./pages/draftEvent/DraftEvent";
import OrganizerDashboard from "./pages/dashboard/OrganizerDashboard";
import CreateOrganizerProfile from "./pages/profile/create/CreateOrganizerProfile";
import PreviewPanel from "./components/createEvent/PreviewPanel";
import { fetchLoginRequest } from "./redux/actions/Login";
import PublishEvent from "./pages/createEvent/PublishEvent";
import Navbar from "./components/navbar/Navbar";
import AboutUs from "./components/footer/AboutUs";
// import SupportAndFAQ from "./components/footer/SupportAndFAQ";
// import TermsAndConditions from "./components/footer/TermsAndConditions";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";
import ServiceCharges from "./components/footer/ServiceCharges";
import BookingResponse from "./pages/bookingResponse/BookingResponse";
import OrganizerAnnouncementPage from "./pages/announcement/OrganizerAnnouncementPage";
import PublishEventSuccess from "./pages/createEvent/PublishEventSuccess";
import { GetCurrentLocation } from "./utils/commonFun";
import ActiveEventList from "./pages/activeEvent/ActiveEventList";
import OrganizerSalesDetails from "./pages/dashboard/OrganizerSalesDetails";
import ViewEventDetailsDashboard from "./components/dashboard/eventTable/ViewEventDetailsDashboard";
import Policies from "./components/footer/Policies";
import AnnouncementModel from "./components/announcement/AnnouncementModel";
import Widget from "./pages/widgets/Widget";
import CreateEventAd from "./pages/createEvent/admin/CreateEventAd";

//
function App({ fetchLogin }) {
  useEffect(() => {
    fetchLogin();
  });
  const [userData, setUser] = useState(null);
  /* //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/"); */
  let currentLocation = GetCurrentLocation();
  let mainConatiner = "container text-center topmargin ";
  //switch(splitLocation[1]){}
  switch (currentLocation?.mainRoute) {
    case "dashboard":
    case "create-event":
    case "active-event":
    case "past-event":
    case "draft-event":
    case "my-account":
    case "my-announcement":
    case "event-sales-details":
    case "view-event-details":
    case "send-announcement":
      mainConatiner = "container-fluid topmargin";
      break;
  }
  //Widget
  let showWidget = false;
  if (currentLocation?.mainRoute.toLowerCase() === "widget") showWidget = true;
  if (currentLocation?.mainRoute.toLowerCase() === "create-event-ad")
    showWidget = true;
  //main return
  return (
    // <AuthProvider>
    // <CityProvider>
    <Fragment>
      {showWidget === true ? (
        <>
          <Routes>
            <Route path="/widget/*" element={<Widget />} />
            <Route path="/create-event-ad/*" element={<CreateEventAd />} />
          </Routes>
        </>
      ) : (
        <>
          <Navbar />
          <div
            class={mainConatiner}
            /* style={{ marginTop: 88 }} */
          >
            <div id="eventto" className="hide"></div>
            <Routes>
              <Route index element={<Home />} user={userData} />
              <Route path="*" element={<NoPage />} />
              <Route path="/search/*" element={<SearchAll />} />
              {/* <Route path="/event/*" element={<Events />} /> */}
              <Route
                path="/event/:eventCity/:eventCityId"
                element={<Events />}
              />
              <Route
                path="/category/:categoryName/:categoryId"
                element={<Category />}
              />
              <Route
                path="/eventdetails/:eventId/:eventName"
                element={<ViewEventDetail />}
              />
              <Route
                path="/event-ticket-options/:eventId/:eventName/*"
                element={<EventTicketOptions />}
              />
              <Route
                path="/event-checkout/:eventId/:eventName/*"
                element={<EventCheckOut />}
              />
              <Route
                path="/view-event-details/:eventId/*"
                element={<ViewEventDetailsDashboard />}
              />
              {/* <Route path="/my-orders" element={<UserTabs activeTab={0} />} />
            <Route path="/my-profile" element={<UserTabs activeTab={1} />} />
            <Route path="/notifications" element={<UserTabs activeTab={2} />} />
            <Route
              path="/account-settings"
              element={<UserTabs activeTab={3} />}
            /> */}
              <Route path="/my-info/*" element={<ParticipantInfo />} />
              <Route path="/dashboard" element={<OrganizerDashboard />} />
              <Route
                path="/event-sales-details/:eventId/:eventName"
                element={<OrganizerSalesDetails />}
              />
              {/* <Route path="/create-event1" element={<CreateEvent />} /> */}
              <Route path="/create-event/*" element={<CreateEvent />} />
              <Route path="/active-event" element={<ActiveEventList />} />
              <Route path="/past-event" element={<PastEventList />} />
              <Route path="/draft-event" element={<DraftEvent />} />
              <Route path="/my-account" element={<ViewOrganizerProfile />} />
              {/* Announcement */}
              <Route
                path="/my-announcement"
                element={<OrganizerAnnouncementPage />}
              />
              <Route
                path="/send-announcement"
                element={<AnnouncementModel />}
              />
              <Route
                path="/create-profile"
                element={<CreateOrganizerProfile />}
              />
              <Route
                path="/publish-event/:eventId/:eventName"
                element={<PublishEvent />}
              />
              <Route
                path="/publish-event-success/:eventId/:eventName"
                element={<PublishEventSuccess />}
              />
              // This needs to be removed if we are not using @Akash.
              <Route path="/preview-event" element={<PreviewPanel />} />
              {/* Fit Funda Footer */}
              <Route path="/*" element={<AboutUs />} />
              {/* <Route path="/sitemap" element={<ServiceCharges />} /> */}
              {/* <Route path="/blog" element={<PrivacyPolicy />} /> */}
              {/* <Route path="/*" element={<ServiceCharges />} /> */}
              <Route path="/pricing" element={<ServiceCharges />} />
              <Route path="/policies" element={<Policies />} />
              {/* End of Fit Funda Footer */}
              <Route path="/booking-status?" element={<BookingResponse />} />
            </Routes>
          </div>
          <Footer />
        </>
      )}
    </Fragment>

    // </CityProvider>
    // </AuthProvider>
  );
}

//console.log(store.getState()); // { a: 'lol', b: 'wat' }

const mapDispatchToProps = (dispatch) => ({
  fetchLogin: () => dispatch(fetchLoginRequest()),
});

export default connect(null, mapDispatchToProps)(App);

//export default App;
