import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { GetEventDetailsData } from "../../services/eventAPI";
import EventCardTypeSmall from "../../components/event/EventCardSmall";
import CheckOutSummary from "../../components/checkout/CheckoutSummary";
import {
  addToCartEventRequest,
  fetchCartEventsRequest,
} from "../../redux/actions/events";
import AccordionUserProfile from "../../components/checkout/AccordionUserProfile";
import Spinner from "../../components/controls/spinner/Spinner";

const EventCheckOut = ({ cartEvents, fetchSummary }) => {
  const { eventId } = useParams();
  const { eventName } = useParams();
  // let total = [];
  const [paymentBreakup, setPaymentBreakup] = useState("");

  //const {eventID,eventName} =useParams();
  //load data
  // const [eventDetailsData] = GetEventDetailsData();
  /*   useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);
 */

  console.log("Cart Details", cartEvents);
  const [eventDetailsData, setEventDetailsData] = useState("");
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const eventDetailsResponseData = await GetEventDetailsData(eventId);
        if (eventDetailsResponseData.success === true) {
          setEventDetailsData(eventDetailsResponseData.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEventDetails();
  }, []);

  // const initiatePayment = async () => {
  //   const result = await InitiatePayment("", "", "");

  //   // window.location.href = result.url;
  // };

  // const loadTicketCount = () => {
  //   for (const key in cartEvents) {
  //     const count = cartEvents[key].orderQuantity;
  //     for (let index = 0; index < count; index++) {
  //       total.push(parseInt(cartEvents[key].orderQuantity));
  //     }
  //   }
  // };

  // const renderItems = () => {
  //   loadTicketCount();
  //   const renderedItems = [];
  //   for (const key in total) {
  //     renderedItems.push(<li key={key}>{<CheckoutForm />}</li>);
  //   }
  //   return renderedItems;
  // };

  return (
    <>
     {loading ? (
        <Spinner/>
        ) : (
      eventDetailsData && (
        <>
          <div class="row mx-2">
            <div class="col text-start cat-name">
              {/* <b>Events</b> {eventName} */}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-3 col-md-8 col-xl-9">
              <EventCardTypeSmall eventDetailsData={eventDetailsData} />
              {/*  <!-- Accordion --> */}
              {/*   <div
                class="accordion accordion-flush border-top border-bottom mb-5"
                id="accordionFlushExample"
              >
                <div>
                  <ul>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onClick={initiatePayment}
                    >
                      Submit
                    </button>
                    {renderItems()}
                  </ul>
                </div>
              </div> */}
              <AccordionUserProfile
                cartEvents={cartEvents}
                eventDetailsData={eventDetailsData}
                paymentBreakup={paymentBreakup}
              />
            </div>

            <div className="col-sm-3 mt-3 d-sm-none d-md-block col-md-4 col-xl-3">
              <CheckOutSummary
                summaryData={cartEvents}
                eventDetailsData={eventDetailsData}
                paymentBreakup={(value) => setPaymentBreakup(value)}
                cameFrom="EventCheckOut"
              />
            </div>
          </div>
        </>
      )
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cartEvents: state.events.cartEvents.cartEvents,
  /*  state.events.cartEvents.cartEvents != []
      ? state.events.cartEvents.cartEvents[0]
      : state.events.cartEvents.cartEvents, */
});
const mapDispatchToProps = (dispatch) => ({
  //addSummary: (summary) => dispatch(addToCartEventRequest(summary)),
  fetchSummary: () => dispatch(fetchCartEventsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCheckOut);
//export default EventCheckOut;
